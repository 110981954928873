import { api, apiObject } from "./api";

const URLS = {
    user: "user",
    userListings: "directories/current-user",
    serviceFormParams: "formValues",
    changePassword: "change-password",
};

export const getUserProfile = (payload) => {
    return api.get(`${URLS.user}/${payload.id}`);
};

export const currentUserListings = (payload) => {
    return api.get(
        `${payload.userType}/${URLS.userListings}?page=${payload.page}&items=${payload.items}`
    );
};

export const getServiceFormParams = () => {
    return api.get(URLS.serviceFormParams);
};

export const deleteAccount = ({ id }) => {
    return api.delete(`${URLS.user}/${id}/delete`);
};

export const updatePassword = (payload) => {
    return api.put(URLS.changePassword, payload);
};

export const updateProfile = ({ id, data }) => {
    return api.patch(`${URLS.user}/${id}/edit`, data);
};

export const uploadFile = ({ type, data }) => {
    return api.post(type, data);
};
