<template>
    <v-row justify="center">
        <v-col cols="12" md="10">
            <h2 class="main-heading">My listings</h2>
            <v-row>
                <v-col>
                    <base-card class="fill-height">
                        <template #content>
                            <v-card-text class="flex-col-between fill-height">
                                <v-data-table
                                    :headers="headers"
                                    :items="items"
                                    :loading="getListingsStatus_Pending"
                                    :loading-text="
                                        $t(`Admin.dataTable.loadingText`)
                                    "
                                    :no-data-text="
                                        $t(`Admin.dataTable.noDataText`)
                                    "
                                    :no-results-text="
                                        $t(`Admin.dataTable.noResultsText`)
                                    "
                                    :page.sync="page"
                                    hide-default-footer
                                    disable-sort
                                >
                                    <template v-slot:top>
                                        <v-row class="mb-4">
                                            <v-col
                                                cols="12"
                                                class="d-flex justify-end"
                                            >
                                                <v-btn
                                                    depressed
                                                    dense
                                                    outlined
                                                    link
                                                    :to="
                                                        computedCreateNewRedirect
                                                    "
                                                    color="primary"
                                                >
                                                    <v-icon
                                                        size="14"
                                                        class="mr-2"
                                                    >
                                                        fa-solid fa-plus
                                                    </v-icon>
                                                    {{
                                                        $t(
                                                            `Admin.sme.createNew`
                                                        )
                                                    }}
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </template>
                                    <template v-slot:item.actions="{ item }">
                                        <dropdown-menu
                                            :is-text="true"
                                            :can-close="true"
                                            icon="fas fa-ellipsis-v"
                                        >
                                            <template #listItems>
                                                <v-list>
                                                    <v-list-item
                                                        class="item-hover px-0"
                                                    >
                                                        <v-btn
                                                            text
                                                            color="accent darken-4"
                                                            @click="
                                                                editItem(item)
                                                            "
                                                        >
                                                            <v-icon
                                                                size="12"
                                                                class="mr-2"
                                                            >
                                                                fa-solid fa-pen
                                                            </v-icon>
                                                            <span
                                                                class="table-menu-item"
                                                                >{{
                                                                    $t(
                                                                        `Admin.dataTable.tableActions.edit`
                                                                    )
                                                                }}</span
                                                            >
                                                        </v-btn>
                                                    </v-list-item>
                                                    <v-list-item
                                                        class="item-hover px-0"
                                                    >
                                                        <v-btn
                                                            text
                                                            color="error lighten-1"
                                                            @click="
                                                                deleteItem(item)
                                                            "
                                                        >
                                                            <v-icon
                                                                size="12"
                                                                class="mr-2"
                                                            >
                                                                fa-solid
                                                                fa-trash
                                                            </v-icon>
                                                            <span
                                                                class="table-menu-item"
                                                                >{{
                                                                    $t(
                                                                        `Admin.dataTable.tableActions.delete`
                                                                    )
                                                                }}</span
                                                            >
                                                        </v-btn>
                                                    </v-list-item>
                                                </v-list>
                                            </template>
                                        </dropdown-menu>
                                    </template>
                                </v-data-table>

                                <div v-if="pageCount > 1">
                                    <v-divider class="mb-4"></v-divider>

                                    <div class="text-center table-pagination">
                                        <v-pagination
                                            v-model="page"
                                            class="table-pagination"
                                            :length="pageCount"
                                            :total-visible="7"
                                            elevation="0"
                                        ></v-pagination>
                                    </div>
                                </div>
                            </v-card-text>
                        </template>
                    </base-card>
                    <confirm-modal :open="openModal" :close.sync="openModal">
                        <template #content>
                            <span class="modal-content">
                                {{ computedModalText }}
                            </span>
                        </template>
                        <template #action>
                            <v-btn
                                depressed
                                dense
                                color="error lighten1"
                                class="mr-2"
                                :loading="confirmStatus_Pending"
                                @click="confirm()"
                            >
                                {{ $t(`Admin.modal.confirm`) }}
                            </v-btn>
                        </template>
                    </confirm-modal>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import { apiStatus } from "../api/constants/apiStatus";
import { apiStatusComputed } from "../api/helpers/computedApiStatus";
import { withAsync } from "@/helpers/withAsync";
import { currentUserListings } from "../api/userApi.js";

import { deleteDih, deleteSme } from "@/api/adminApi.js"

import BaseCard from "../components/base/BaseCard.vue";
import DropdownMenu from "./adminPages/components/DropdownMenu.vue";
export default {
    name: "MyListings",

    components: {
        BaseCard,
        DropdownMenu,
        ConfirmModal: () => import("../components/ConfirmModal.vue"),
    },

    data() {
        return {
            items: [],

            page: 1,
            pageCount: 0,

            openModal: false,
            itemToDelete: {},

            getListingsStatus: apiStatus.Idle,
            confirmStatus: apiStatus.Idle,

            userType: null,
        };
    },

    computed: {
        ...apiStatusComputed(["getListingsStatus", "confirmStatus"]),
        ...mapGetters({
            userRole: "auth/getUserRole",
        }),
        computedCreateNewRedirect() {
            if (this.userType === "sme") return { name: "CreateEditSme" };
            else if (this.userType === "dih") return { name: "CreateEditDih" };
            else return { name: "NotFound" };
        },

        computedModalText() {
            if (this.userType === "sme") return this.$t(`Admin.sme.confirmTxt`);
            else return this.$t(`Admin.dih.confirmTxt`);
        },
        headers() {
            return [
                { text: "Id", value: "id" },
                {
                    text: this.$t(`Admin.dataTable.headers.title`),
                    value: "title",
                },
                {
                    text: this.$t(`Admin.dataTable.headers.description`),
                    value: "description",
                },
                {
                    text: this.$t(`Admin.dataTable.headers.user`),
                    value: "user.name",
                },
                {
                    text: this.$t(`Admin.dataTable.headers.actions`),
                    value: "actions",
                    sortable: false,
                },
            ];
        },
    },

    methods: {
        async getListings() {
            this.getListingsStatus = apiStatus.Pending;

            const payload = {
                page: this.page,
                items: 8,
                userType: this.userType,
            };

            const { response, error } = await withAsync(
                currentUserListings,
                payload
            );

            if (error) {
                this.getListingsStatus = apiStatus.Error;
                return;
            }

            this.items = response.data.data;
            this.pageCount = response.data.last_page;
            this.getListingsStatus = apiStatus.Success;
        },

        editItem(item) {
            if (this.userType === "sme")
                this.$router.push({
                    name: "CreateEditSme",
                    params: { id: item.id },
                });
            else if (this.userType === "dih")
                this.$router.push({
                    name: "CreateEditDih",
                    params: { id: item.id },
                });
        },

        deleteItem(item) {
            this.itemToDelete = item;
            this.openModal = true;
        },

        async confirm() {
            this.confirmStatus = apiStatus.Pending;
            const payload = {
                id: this.itemToDelete.id,
            };

            let action;
            if(this.userType === "dih") action = deleteDih;
            else if(this.userType === "sme") action = deleteSme;
            
            const { response, error } = await withAsync(action, payload);

            if (error) {
                this.confirmStatus = apiStatus.Error;
                return;
            }
            this.confirmStatus = apiStatus.Success;
            this.openModal = false;
            this.getListings();
        },
    },

    watch: {
        page(newVal) {
            this.getListings();
        },

        openModal(newVal){
            if(!newVal) this.itemToDelete = {};
        }
    },

    created() {
        let user_type;
        //Convert user-role to number to check condition
        if (+this.userRole === 1) this.userType = "dih";
        if (+this.userRole === 2) this.userType = "sme";

        this.getListings();
    },
};
</script>
